import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StartComponent } from './start/start.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GameComponent } from './game/game.component';
import { BrowserComponent } from './browser/browser.component';


@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    GameComponent,
    BrowserComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
      AppRoutingModule,
      NgbModule,
      MDBBootstrapModule.forRoot(),
      FormsModule,
      HttpClientModule,
      ReactiveFormsModule,

  ],
  providers: [{provide: Window, useValue: window},],
  bootstrap: [AppComponent]
})
export class AppModule { }
