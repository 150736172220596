import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder,ReactiveFormsModule, FormControl, Validators } from '@angular/forms';

export interface UnoLobbyProperties {
  serverName: string;
  startcards: number;

}

export interface UnoLobbyElement {
  id: string;
  properties?: UnoLobbyProperties;
  status?: boolean;
  players?: number;

}

@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.scss']
})
export class BrowserComponent implements OnInit {

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private formBuilder: FormBuilder,
  ) { 
    this.lobbies = []
  }

  createForm;
  nick: string;

  ngOnInit(): void {

    this.createForm = this.formBuilder.group({
      serverName: ['Neue Sitzung', [<any>Validators.required, <any>Validators.minLength(3), <any>Validators.maxLength(20)]],
      startcards: [7, [<any>Validators.required, <any>Validators.min(2), <any>Validators.max(20)]],
      sw_stacking: [false, [<any>Validators.required]],
      sw_jumpin: [false, [<any>Validators.required]],
      sw_double: [false, [<any>Validators.required]],
      sw_sleep: [false, [<any>Validators.required]],
      sw_seveno: [false, [<any>Validators.required]],
    });
    

    this.nick = localStorage.getItem('username')
    if (!this.nick){
      this.router.navigate(['/start']);
    }
    this.loadLobbies();
  }

  lobbies: UnoLobbyElement[];

  loadLobbies(){
    this.httpClient.get("/unogame/lobbies").subscribe(result => {
      const cres: any = result;
      if (cres.success) {
          console.log(cres.data)
          this.lobbies = cres.data ? cres.data : [];
      }
    });
  }

  prepareModal(){
    
  }

  createLobby(){

    console.log("create" , this.createForm.value);  
    if(this.createForm.valid){
      document.getElementById("closeModalButton").click();
      this.httpClient.post("/unogame/create",this.createForm.value).subscribe(result => {
        const cres: any = result;
        if (cres.success) {
            console.log(cres.data.id)
            this.joinGame({id: cres.data.id})
        }
      });
    }

  }

  joinGame(lobby: UnoLobbyElement){
    this.httpClient.post("/unogame/join", { 
      nick: localStorage.getItem('username'),
      lid: lobby.id
    }).subscribe(result => {
      const cres: any = result;
      if (cres.success) {
          console.log(cres.data)
          localStorage.setItem('uid', cres.data.userId);
          localStorage.setItem('session', lobby.id);
          this.router.navigate(['/game']);
      }
  });
  }

}
