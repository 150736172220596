<div class="wrapper fill">
  <!-- Sidebar -->
  <nav id="sidebar" class="playerlist">
    <h1 style="padding:0.5rem;">Hallo, {{thisPlayer.nickname}}</h1>
    <div class="player card" *ngFor="let player of winnerList; let i = index">
      <div class="card-body" style="padding: 0px;">
        <div id="textbox">
          <p class="alignleft lessspace playername">{{player.nickname}}</p>
          <p class="alignright lessspace">
            <span *ngIf="!(player.uno && player.cards == 1)" class="badge badge-warning">{{i + 1}}.</span>
          </p>
        </div><br />
        <div id="textbox" class="lessspace">
          <p> Spiel beendet </p>
        </div>
        <div id="textbox" class="lessspace" style="margin-top:-12px;">
          <p class="alignleft lessspace ">Runde {{player.rounds}}</p>
          <p class="alignright lessspace">{{player.score}} Punkte</p>
        </div>
      </div>
    </div>
    <hr *ngIf="winnerList?.length > 0" />
    <div class="player card" *ngFor="let player of playerList" [style.background-color]="player.userId == currentPlayer.userId?'#90EE90':(nextPlayerId == player.userId ?'#FFFFE0':'#FFF')">
      <div class="card-body" style="padding: 0px;">
        <div id="textbox">
          <p class="alignleft lessspace playername">{{player.nickname}}</p>
          <p class="alignright lessspace">
            <span *ngIf="player.uno && player.cards == 1" class="badge badge-danger">UNO</span>
            <span *ngIf="!(player.uno && player.cards == 1)" class="badge badge-secondary">{{player.cards}}</span>
          </p>
        </div><br />
        <div id="textbox" class="lessspace">
          <p>
            <span>{{player.state}}</span>
            -
            <span *ngIf="!gameStarted && !player.ready">In Lobby (Nicht bereit)</span>
            <span *ngIf="!gameStarted && player.ready">In Lobby (Bereit)</span>
            <span *ngIf="gameStarted && !(player.userId == currentPlayer.userId)">Wartet</span>
            <span *ngIf="gameStarted && player.userId == currentPlayer.userId">Am Zug</span>
          </p>
        </div>
        <div id="textbox" class="lessspace" style="margin-top:-12px;">
          <p class="alignleft lessspace ">Runde {{player.rounds}}</p>
          <p class="alignright lessspace">{{player.score}} Punkte</p>
        </div>
      </div>
    </div>

    <div class="footer">
      <button mdbBtn type="button" *ngIf="!thisPlayer.ready" rounded="true" mdbWavesEffect class="btn btn_btm btn-success" (click)="ready()">Bereit</button>
      <button mdbBtn type="button" rounded="true" mdbWavesEffect class="btn btn_btm btn-warning" (click)="leave()">Verlassen</button>
    </div>
  </nav>

  <!-- Page Content -->
  <div id="content">
    <!-- We'll fill this with dummy content -->
    <div class="gamefield">
      <div class="footer2" [innerHTML]="chatText" #scrollMe [scrollTop]="scrollMe.scrollHeight"></div>
      <div class="card countdown" *ngIf="timer >= 0">
        <p><i class="fas fa-stopwatch"></i> {{timer}}</p>
      </div>
      
      <div class="card votebox" [@simpleFadeAnimation]="'in'" *ngIf="currentVote">
        <div class="card-header">
          {{currentVote.questions[0].question}}
        </div>
        <ul class="list-group list-group-flush">
     
          <li class="list-group-item vote-option" (click)="vote(i)"  *ngFor="let opt of currentVote.questions[0].options; let i = index">
            <div class="progress" [style.width]="((currentVote.questions[0].counter[i] / currentVote.all )*100) + '%'"></div>{{opt}}
          </li>

        </ul>
      </div>
      
      <div class="text-header" *ngIf="gameStarted">
        <p class="text-header-current" *ngIf="!(currentPlayer.userId == thisPlayer.userId)">{{currentPlayer.nickname}} ist am Zug</p>
        <p class="text-header-current" *ngIf="currentPlayer.userId == thisPlayer.userId">Du bist am Zug</p>
        <p class="text-header-detail" *ngIf="topCard.color=='blue'">Aktuell wird <span style="color:#0095da">Blau</span> gespielt</p>
        <p class="text-header-detail" *ngIf="topCard.color=='green'">Aktuell wird <span style="color:#00ab67">Grün</span> gespielt</p>
        <p class="text-header-detail" *ngIf="topCard.color=='red'">Aktuell wird <span style="color:#ed1c24">Rot</span> gespielt</p>
        <p class="text-header-detail" *ngIf="topCard.color=='yellow'">Aktuell wird <span style="color:#ffde00">Gelb</span> gespielt</p>
        <p class="text-header-detail" *ngIf="topCard.color=='wild'">{{currentPlayer.nickname}} sucht sich eine Farbe aus</p>
      </div>
      <div class="text-header" *ngIf="!gameStarted">
        <p class="text-header-current">Warte bis alle Spieler bereit sind</p>
      </div>
      <div class="play-field" *ngIf="gameStarted">
        <div class="cards-center">
          <button type="button" class="btn uno-card" style="margin-right:8rem;" [style.background]="'url('+ topCard.tx +')'"></button>
          <button type="button" class="btn uno-card" style="margin-right:0;" [style.background]="'url(/assets/cards/000.png)'" placement="bottom" ngbTooltip="Karte ziehen" (click)="pull()"></button>
          <br /><br />
          <button mdbBtn type="button" rounded="true" mdbWavesEffect class="btn btn-skip btn-warning" (click)="skip()" *ngIf="(currentPlayer.userId == thisPlayer.userId) && pulled">Aussetzen</button>
          <button mdbBtn type="button" rounded="true" mdbWavesEffect class="btn btn-skip btn-danger" (click)="uno()" *ngIf="(currentPlayer.userId == thisPlayer.userId)">UNO!</button>
        </div>
      </div>
    </div>
    
    <div class="notify-banner" *ngIf="bannerShow && !bannerError" [@simpleFadeAnimation]="'in'">
      {{bannerText}}
    </div>
    <div class="notify-banner" *ngIf="bannerShow && bannerError" style="color:#ed1c24" [@simpleFadeAnimation]="'in'">
      {{bannerText}}
    </div>
    <div class="footer3">
      <div *ngFor="let card of handCards" [@flyInOut]="'in'">
        <button *ngIf="!((card.number == 'wild+4') || (card.number == 'wild'))" type="button" class="btn  uno-card uno-overlap " [style.background]="'url('+ card.tx +')'" placement="bottom" ngbTooltip="Karte legen" (click)="selectedCard=card;play(card)"></button>
        <button *ngIf="card.number == 'wild+4'" type="button" class="btn  uno-card uno-overlap " [style.background]="'url('+ card.tx +')'" placement="bottom" ngbTooltip="Karte legen" data-toggle="modal" data-target="#modal_plus4" (click)="selectedCard=card"></button>
        <button *ngIf="card.number == 'wild'" type="button" class="btn  uno-card uno-overlap " [style.background]="'url('+ card.tx +')'" placement="bottom" ngbTooltip="Karte legen" data-toggle="modal" data-target="#modal_wild" (click)="selectedCard=card"></button>

      </div>

    </div>
   
    
  </div>

</div>



<!-- Modal -->
<div class="modal fade" id="modal_plus4" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="cards-center">
        <button type="button" class="btn uno-card" style="margin-right:0;" [style.background]="'url(/assets/cards/312.png)'" placement="bottom" ngbTooltip="Blau wünschen" (click)="playwild('wild+4','blue')" data-dismiss="modal"></button>
        <button type="button" class="btn uno-card" style="margin-right:0;" [style.background]="'url(/assets/cards/322.png)'" placement="bottom" ngbTooltip="Grün wünschen" (click)="playwild('wild+4','green')" data-dismiss="modal"></button>
        <button type="button" class="btn uno-card" style="margin-right:0;" [style.background]="'url(/assets/cards/332.png)'" placement="bottom" ngbTooltip="Rot wünschen" (click)="playwild('wild+4','red')" data-dismiss="modal"></button>
        <button type="button" class="btn uno-card" style="margin-right:0;" [style.background]="'url(/assets/cards/342.png)'" placement="bottom" ngbTooltip="Gelb wünschen" (click)="playwild('wild+4','yellow')" data-dismiss="modal"></button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="modal_wild" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="cards-center">
        <button type="button" class="btn uno-card" style="margin-right:0;" [style.background]="'url(/assets/cards/311.png)'" placement="bottom" ngbTooltip="Blau wünschen" (click)="playwild('wild','blue')" data-dismiss="modal"></button>
        <button type="button" class="btn uno-card" style="margin-right:0;" [style.background]="'url(/assets/cards/321.png)'" placement="bottom" ngbTooltip="Grün wünschen" (click)="playwild('wild','green')" data-dismiss="modal"></button>
        <button type="button" class="btn uno-card" style="margin-right:0;" [style.background]="'url(/assets/cards/331.png)'" placement="bottom" ngbTooltip="Rot wünschen" (click)="playwild('wild','red')" data-dismiss="modal"></button>
        <button type="button" class="btn uno-card" style="margin-right:0;" [style.background]="'url(/assets/cards/341.png)'" placement="bottom" ngbTooltip="Gelb wünschen" (click)="playwild('wild','yellow')" data-dismiss="modal"></button>
      </div>
    </div>
  </div>
</div>




<!--Rules Modal-->
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" id="open_rules_modal" data-toggle="modal" data-target="#rulesModal" [hidden]="true">
  Launch modal
</button>

<!-- Modal -->
<div class="modal fade" id="rulesModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Aktive Sonderregeln</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div style="text-align: left;">
          <span class="rule"><b>Mehr Karten</b> Jeder hat zu Beginn {{options.cards}} statt 7 Karten. </span><br />
          <span class="rule"><b>Doppeln</b> Hat ein Spieler zwei Karten von der selben Farbe und Zahl auf der Hand, so darf er diese zusammen ablegen. Dies gilt jedoch nicht für die +2- und +4-Karten. Diese müssen weiterhin nacheinander abgelegt werden. </span><br />
          <span class="rule"><b>Kumulieren</b> Kann ein Spieler, der von eine +2-Karte betroffen ist, selbst mit einer solchen Karte aufwarten, so i8st dies erlaubt und der nächste Spieler nach ihm muss nun 4 Karten ziehen. Hat dieser ebenfalls eine +2-Karte, so ist auch dies zulässig. Gleiches gilt entsprechend für die +4-Karten. Die Karten dürfen allerdings nicht kombiniert werden.  </span><br />
          <span class="rule"><b>Jump-In</b> Wenn ein Spieler exakt die gleiche Karte auf der Hand hat, welche soeben ausgespielt wurde (gleiche Farbe und Zahl), so kann er diese sofort ablegen – auch wenn er nicht am Zug ist. Die Runde geht jedoch bei dem Spieler weiter, welcher regulär an der Reihe gewesen wäre. </span><br />
          <span class="rule"><b>Pennen</b> Wer seinen Einsatz verpasst, muss eine Strafkarte ziehen. </span><br />
          <span class="rule"><b>Seven-O (Sieben-Null)</b> Wird eine Null gespielt, so muss jeder Spieler sein komplettes Blatt an den Spielnachbarn in Uhrzeigerrichtugn weitergeben. Wird dagegen eine Sieben ausgespielt, so darf dieser Spieler sein komplettes Blatt mit einem anderen Spieler seiner Wahl tauschen. </span><br />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal">Alles klar!</button>
      </div>
    </div>
  </div>
</div>

<!--double modal-->
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" id="open_rdouble_modal" data-toggle="modal" data-target="#modal_double" [hidden]="true">
  Launch modal
</button>

<div class="modal fade" id="modal_double" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="cards-center">
        <button type="button" class="btn uno-card double-card" style="margin-right:0;" (click)="playSingle()" [style.background]="'url('+ selectedCard?.tx +')'" placement="bottom" ngbTooltip="Einzelne Karte legen" data-dismiss="modal">1x</button>
        <button type="button" class="btn uno-card double-card" style="margin-right:0;" (click)="playDouble()" [style.background]="'url('+ selectedCard?.tx +')'" placement="bottom" ngbTooltip="Doppelt legen" data-dismiss="modal">2x</button>
      </div>
    </div>

  </div>
</div>
