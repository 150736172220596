<div class="row h-100">
  <div class="col-sm-12 h-100 d-table">
    <div class="card card-block d-table-cell align-middle">
      <div class="start-box">
        <h1>Wie heißt du ?</h1>
        <br />
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">@</span>
          </div>
          <input mdbInput  type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" [(ngModel)]="nick">
        </div>
        <span class="error" *ngIf="nick.length > 15">Der Benutzername ist zu lange</span>
        <span class="error" *ngIf="nick.length < 3">Der Benutzername ist zu kurz</span>
        <div class="input-group mb-3 ">
          <button  block="true" [disabled]="!buttonEnabled || (nick.length > 15) || (nick.length < 3)" mdbBtn type="button" gradient="peach" rounded="true" mdbWavesEffect class="btn" (click)="start()">Abfahrt!</button>
        </div>
      </div>
     
    </div>
  </div>
</div>
