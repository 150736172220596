<div>
    <div class="card lobby-card">
        <div class="card-header">
          <h1><b>UNO Sitzungen</b> - {{nick}}</h1>
          <hr />
          <button class="btn btn-success btn-sm" (click)="prepareModal()" data-toggle="modal" data-target="#basicExampleModal" style="margin-right: 1rem;"><i class="fas fa-magic mr-1"></i> Sitzung erstellen</button>
          <button class="btn btn-success btn-sm" style="margin-right: 1rem;"><i class="fas fa-random mr-1"></i> Zufäliger Sitzung beitreten</button>
          <button class="btn btn-primary btn-sm" (click)="loadLobbies()"><i class="fas fa-sync mr-1"></i> Übersicht Aktualisieren</button>
          <button class="btn btn-warning btn-sm" routerLink="/start" style="position:absolute; right: 1.25rem;"><i class="fas fa-edit mr-1"></i>Benutzernamen ändern</button>
        
        </div>
        <ul class="list-group list-group-flush" *ngIf="lobbies.length > 0">
            <li class="list-group-item" *ngFor="let lobby of lobbies">
                <div class="container">
                    <div class="row">
                      <div class="col-sm btn-align" style="font-weight: 500;">
                        {{lobby.properties.serverName}} 
                        <span *ngIf="lobby.status">(Im Spiel)</span>
                        <span *ngIf="!lobby.status">(in Lobby)</span>
                      </div>
                      <div class="col-sm btn-align">
                        {{lobby.players}} Spieler
                      </div>
                      <div class="col-sm btn-align">
                        <ul>
                          <li *ngIf="!(lobby.properties.startcards == 7)">{{lobby.properties.startcards}} Karten zu Spielbeginn</li>
                          <li *ngIf="!(lobby.properties.sw_stacking == 7)">Kumulieren</li>
                          <li *ngIf="!(lobby.properties.sw_jumpin == 7)">Jump-In</li>
                          <li *ngIf="!(lobby.properties.sw_double == 7)">Doppeln</li>
                          <li *ngIf="!(lobby.properties.sw_sleep == 7)">Pennen</li>
                          <li *ngIf="!(lobby.properties.sw_seveno == 7)">Seven-O (Sieben-Null)</li>
                        
                        </ul>
                      </div>
                      <div class="col-sm">
                        <button class="btn btn-success btn-sm" [disabled]="lobby.status" (click)="joinGame(lobby)"><i class="fas fa-caret-square-right mr-1"></i>Spiel Beitreten</button>
                      </div>
                    </div>
                  </div>
            </li>
           
          </ul>
          <ul class="list-group list-group-flush" *ngIf="lobbies.length == 0">
            <li class="list-group-item text-muted" >
               Es gibt keine aktiven Sitzungen.
            </li>
           
          </ul>
        <div class="card-footer text-muted">
            Programmiert von Dennis Gunia (2020) - Open Source UNO - <a href="https://www.dennisgunia.de">dennisgunia.de</a> - Quellcode: <a href="https://gitlab.dennisgunia.de/dennisgunia/uno-online/-/tree/master">gitlab.dennisgunia.de</a>
          </div>
      </div>
      
</div>


<!-- Modal -->
<div class="modal fade" id="basicExampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Neue Sitzung erstellen</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="createForm" (ngSubmit)="createLobby()">
        <div class="modal-body">
          <label for="sessionname">Sitzungsname</label>
          <input type="text" id="sessionname" [className]="createForm.controls.serverName.invalid ? ' form-control is-invalid' : 'form-control is-valid'" formControlName="serverName">  <br />
          <label for="startcards">Karten zu beginn</label>
          <input type="number" id="startcards"  min="2" max="20" [className]="createForm.controls.startcards.invalid ? ' form-control is-invalid' : 'form-control is-valid'" formControlName="startcards">  <br />
          <label >Sonderregeln</label>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="sw_stacking" formControlName="sw_stacking">
            <label class="custom-control-label" for="sw_stacking">Kumulieren</label>
          </div>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="sw_jumpin" formControlName="sw_jumpin">
            <label class="custom-control-label" for="sw_jumpin">Jump-In</label>
          </div>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="sw_double" formControlName="sw_double">
            <label class="custom-control-label" for="sw_double">Doppeln</label>
          </div>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="sw_sleep" formControlName="sw_sleep">
            <label class="custom-control-label" for="sw_sleep">Pennen</label>
          </div>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="sw_seveno" formControlName="sw_seveno">
            <label class="custom-control-label" for="sw_seveno">Seven-O (Sieben-Null)</label>
          </div>
          <a href="https://www.uno-kartenspiel.de/uno-spielvarianten/" target="_blank">Infos zu den Sonderregeln</a>
          <button id="closeModalButton" [hidden]="true" data-toggle="modal"        
          data-target="#myModal" class="btn btn-default"                          
         data-dismiss="modal">Close</button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Abbrechen</button>
          <button type="button" type="submit" class="btn btn-success">Sitzung erstellen</button>
        </div>
    </form>
    </div>
  </div>
</div>
