import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';

@Component({
    selector: 'app-start',
    templateUrl: './start.component.html',
    styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {

    nick: string = "";
    buttonEnabled: boolean = true;

    constructor(
        private httpClient: HttpClient,
        private router: Router
    ) { }

    ngOnInit(): void {
        let uname = localStorage.getItem('username');
        if (uname) {
            this.nick = uname;
        }
    }

    public start() {
        //this.buttonEnabled = false;
        localStorage.setItem('username', this.nick);
        this.router.navigate(['/browser']);
        /*
        setTimeout(() => {
            console.log("start " + this.nick);
            this.httpClient.post("/unogame/join", { nick: this.nick }).subscribe(result => {
                const cres: any = result;
                this.buttonEnabled = true;
                if (cres.success) {
                    console.log(cres.data)
                    localStorage.setItem('uid', cres.data.userId);
                    localStorage.setItem('username', this.nick);
                    this.router.navigate(['/game']);
                }
            });
        }, 200);
        */
        

    }
}
